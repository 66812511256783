import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ChairAltOutlinedIcon from "@mui/icons-material/ChairAltOutlined";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Image from "next/image";
import Link from "next/link";
import { useState } from "react";

import { useAuth } from "@/context/auth";
import { Helpers } from "@/utils";
import classNames from "classnames";

type ProductCardProps = {
	id: number;
	imageAlt: string;
	image: string;
	type?: string;
	rScore?: number;
	title: string;
	chair?: number;
	price: number;
	corePrice: number;
	setSelectedImage?: (image: string) => void;
	setOpenDetailImage?: (open: boolean) => void;
	onClickWishlist: (alreadyAddToWishlist: boolean) => void;
	addedToWishlist?: boolean;
	status?: number;
};

export default function ProductCard(props: ProductCardProps) {
	const { isAuthenticated } = useAuth();
	const [addedToWishlist, setAddedToWishlist] = useState(props.addedToWishlist);
	const IconLove = addedToWishlist ? FavoriteIcon : FavoriteBorderRoundedIcon;

	return (
		<div className={`mx-auto flex h-full flex-col bg-white text-left w-[18rem] xl:mx-0 xl:w-[25rem] rounded-xl overflow-hidden mr-6! ${props.status === 2 ? 'grayscale' : ''}`}>
			<div className="relative mx-auto mb-0 block w-full">
				{props.corePrice > 0 && (
					<div className="absolute ml-6 mt-6 rounded-full border border-red-sale bg-red-sale px-4 py-[0.1rem] text-center align-middle text-[1rem] font-bold text-white xl:px-4">
						Sale
					</div>
				)}
				<div className="absolute inset-0 bg-linear-to-t from-white/60 to-transparent" />
				<Link href={`/${props.id}-${Helpers.slugify(props.title)}`}>
					<Image
						blurDataURL="/assets/images/placeholder.png"
						placeholder="blur"
						src={props.image}
						alt={props.imageAlt}
						style={{
							width: "100%",
							height: "auto",
							aspectRatio: "1/1",
						}}
						width={500}
						height={500}
						className="m-0 rounded-lg object-cover object-left-top outline-hidden"
					/>
				</Link>
				<div className="absolute -mt-16 w-full flex gap-3 items-center justify-center mx-auto">
					<button
						type="button"
						onClick={() => {
							if (isAuthenticated) setAddedToWishlist(!addedToWishlist);
							props.onClickWishlist(!addedToWishlist);
						}}
						className="p-2 lg:p-3 rounded-full border border-slate-300 bg-slate-200"
					>
						<IconLove
							className={classNames("size-6 lg:size-12", {
								"text-red-500": addedToWishlist,
							})}
						/>
					</button>
					<button
						type="button"
						className="p-2 lg:p-3 rounded-full border border-slate-300 bg-slate-200"
						onClick={() => {
							props.setSelectedImage?.(props.image);
							props.setOpenDetailImage?.(true);
						}}
					>
						<VisibilityIcon className="size-6 lg:size-12" />
					</button>
				</div>
			</div>
			<div className="w-full bg-white p-6 grid grid-rows-[2rem_8.5rem_2.5rem_4rem_auto]">
				<h2 className="font-bold text-green-product xl:text-[1.125rem]">
					{props.type}
				</h2>
				<Link href={`/${props.id}-${Helpers.slugify(props.title)}`}>
					<h1 className="mb-4 line-clamp-4 overflow-hidden font-bold text-black xl:text-lg">
						{props.title}
					</h1>
				</Link>
				<div className="flex w-full items-center gap-2 text-lg">
					<ChairAltOutlinedIcon className="text-gray-500" />
					<h2 className="font-semibold text-gray-500">
						Sisa kursi: {props.chair}
					</h2>
				</div>
				<div className="xs:mt-4 ms:mt-20 xl:mt-0">
					<p className="text-[1rem] font-semibold xl:text-3xl">
						{Helpers.formatPriceShort(props.price)}
					</p>
					<div className="relative flex justify-between">
						<div className="flex gap-2">
							{(props.corePrice || props.corePrice > 0) && (
								<p className="font-semibold text-gray-soft-ipbt line-through">
									{Helpers.formatPriceShort(props.corePrice)}
								</p>
							)}
							<p className="font-semibold text-red-500 text-lg">
								{props.corePrice > 0 &&
									`${Math.round(
										((props.corePrice - props.price) / props.corePrice) * 100,
									)}%`}
							</p>
						</div>
					</div>
				</div>
				<div className="flex flex-col items-stretch lg:flex-row lg:items-center gap-3 lg:pt-6">
					<Link
						href={`/${props.id}-${Helpers.slugify(props.title)}`}
						className="flex-1"
					>
						<button
							type="button"
							className="bg-green-product text-white px-6 py-3 rounded-full font-bold w-full"
						>
							Learn More
							<ArrowForwardIosRoundedIcon className="size-6 m-auto" />
						</button>
					</Link>
					<Link href={`payment/${props.id}`} className="flex-1">
						<button
							type="button"
							className="bg-blue-ipbt text-white px-6 py-3 rounded-full font-bold w-full"
						>
							Buy now
							<ShoppingCartOutlinedIcon className="size-6 m-auto ml-2" />
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
