import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { IconButton } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Image from "next/image";
import * as React from "react";

type IModalDetailImageProps = {
	open: boolean;
	image: string;
	onClose: () => void;
	alt?: string;
	size?: "lg" | "md" | "sm";
};

const IMAGE_SIZE = {
	sm: 460,
	md: 640,
	lg: 800,
};

export default function ModalDetailImage({
	size = "sm",
	...props
}: IModalDetailImageProps) {
	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={props.open}
				disableScrollLock={true}
				onClose={props.onClose}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
			>
				<Fade in={props.open}>
					<div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 shadow-md outline-hidden">
						<IconButton
							className="absolute right-4 top-4 cursor-pointer bg-slate-400 text-white hover:bg-slate-600"
							onClick={props.onClose}
						>
							<CloseRoundedIcon fontSize="medium" />
						</IconButton>
						<Image
							src={props.image}
							alt={props.alt ?? "Image"}
							width={IMAGE_SIZE[size]}
							height={IMAGE_SIZE[size]}
							blurDataURL="/assets/images/placeholder.png"
							placeholder="blur"
							className="rounded-sm"
						/>
					</div>
				</Fade>
			</Modal>
		</div>
	);
}
