import Link from "next/link";
import classNames from "classnames";

type SectionRequestInhouseProps = {
	variant: "technical" | "ila" | "ldp";
};

const VARIANT_MAP: Record<string, string> = {
	technical: "bg-blue-ipbt",
	ila: "bg-orange-ila",
	ldp: "bg-green-ldp",
};

export function SectionRequestInhouse(props: SectionRequestInhouseProps) {
	return (
		<div
			className={classNames(
				"justify-center py-16 text-center text-white px-4",
				VARIANT_MAP[props.variant],
			)}
		>
			<h3 className="text-lg font-semibold">
				Tidak menemukan yang Anda butuhkan?
			</h3>
			<h1 className="text-xl xl:text-2xl font-bold my-2">
				Pesan program sesuai kebutuhan Anda
			</h1>
			<h4 className="text-sm xl:text-base font-semibold leading-relaxed">
				Kami dapat menyiapkan program yang dikustomisasi <br />
				sesuai dengan kebutuhan dan preferensi Anda
			</h4>
			<Link
				href="request-inhouse"
				className="inline-block mt-4 border border-white py-4 px-8 font-semibold text-white"
			>
				Pesan sekarang
			</Link>
		</div>
	);
}
